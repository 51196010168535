import { getAllClient } from "../Actions/dashboard";
import noImage from "../Assets/Images/no-image.jpg";

export const imageBaseUrl = "https://dygo8x5k8m6hk.cloudfront.net/";

export const apiUrls = {
  login: "/auth/admin/login",
  sendOtp: "/auth/sendOtp",
  verifyOtp: "/auth/verifyOtp",
  resetPassword: "/admin/resetPassword",
  admins: "/admin/admins",
  states: "/states",
  addAdmins: "/admin/addAdmin",
  activatedAccount: "/account/activate",
  suspendAccount: "/account/suspend",
  deleteAccount: "/account/delete",
  getAllClient: "/admin/user/clients",
  getAllInspector: "/admin/inspectors",
  getAllAppraiser: "admin/appraisers",
  getAllReviewer: "/admin/reviewers",
  getOnHoldUsers: "/admin/user/unapprovedusers",
  getallholdinspector: "/admin/inspectors/on-hold",
  getallholdappraisal: "/admin/appraisers/on-hold",
  getallholdreviwer: "/admin/reviewers/on-hold",
  getUsers: "/admin/user/",
  getUserDetail: "/admin/user/detail/",
  updateUserDetail: "/admin/user/detail/",
  getClientDetail: "/admin/client/detail/",
  getClientOrders:"/client/orders",

  getAppraiserOrder: "/admin/appraisal/asign",
  assignAppraiserOrder: "/admin/appraisal/asign",

  getRemainingOrders: "/admin/orders",
  getAnalyticsCount: "admin/dashboard/",
  getOrdersHistory: "admin/orders/order/",
  modifyOrder: "admin/orders/modifyOrder/",
  getRevisionHistory: "admin/orders/revisions/",
  getTopUsers: "/admin/user/topusers",
  getTopClients: "/admin/client/topClients",
  getChatList: "/admin/message/chatList",
  getChatListHistory: "/admin/message/history/",
  sendMessage: "/admin/message/chat/",
  getalltickets: "/admin/tickets",
  getticketById: "/admin/tickets/details",
  getSupportTokenList: "/admin/support",
  getTokenDetail: "/support/",
  supportTokenMessage: "/admin/message/token/",
  getAdminDetail: "/admin/detail/",
  updateAdminDetails: "admin/updateprofile",
  getAssignedOrders: "admin/user/assignedorders/",
  getCompletedOrders: "admin/user/completedorders/",
  getRevisionOrders: "admin/user/revisionorders/",
  getCancelledOrders: "admin/user/cancelledorders/",
};

const getCapitalizedFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return "";
  }
  return str.charAt(0).toUpperCase();
};

export const getInitials = (firstName, lastName) => {
  const firstInitial = getCapitalizedFirstLetter(firstName);
  const lastInitial = getCapitalizedFirstLetter(lastName);

  return `${firstInitial}${lastInitial}`;
};

export const getImageUrl = (image) => {
  return image ? `${imageBaseUrl}${image}` : noImage;
};

export const getStatus = (status) => {
  var data = {
    text: "",
    color: "",
  };
  switch (status) {
    case 1:
      data.text = "Hold";
      data.color = "#F3AF35";
      break;
    case 2:
      // data.text = "Hold"
      // data.color = "#F3AF35"
      data.text = "Active";
      data.color = "#35C736";
      break;
    case 3:
      data.text = "Suspended";
      data.color = "#F3AF35";
      break;
    case 4:
      data.text = "Active";
      data.color = "#35C736";
      break;

    default:
      break;
  }

  return data;
};
