import { Box, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { adminHeaders, superAdminColumns, adminColumns } from '../../Helpers/DataHelper';
import Icon from "../../Helpers/Icon";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins, addAdmins } from "../../Actions/admins";
import { getStates } from "../../Actions/state";
import { deleteAccount, suspendAccount } from "../../Actions/account";
import AlertBox from "../../Components/Alert";
import Grid from "../../Components/DataGrid";
import Button from "../../Components/Core/Button";
import { adminInfoSchema } from "../../Validations";
import AddAdmin from "../../Components/AddAdmin";
import Modal from '@mui/material/Modal';
import { useTheme } from "@mui/material/styles";


const AdminManagement = () => {
  const dispatch = useDispatch();
  const [pageType, setPageType] = useState("Super Admin");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [adminData, setAdminData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cellPhone: '',
    states: [],
  });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const [states, setStates] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => { setOpen(true); setErrors({}); setAdminData({}) };
  const handleClose = () => setOpen(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getAdminData(pageType)
    getStatesData();
  }, []);

  const handleAdminChange = (field, value) => {
    setAdminData(prevData => {
      return {
        ...prevData,
        [field]: value,
      };
    });
  };

  const getAdminData = async (type) => {
    setIsLoading(true);
    setPageType(type);
    setData([])

    const params = {
      type: type,
    };

    try {
      const result = await dispatch(getAdmins(params));
      console.log(result); // This should now have the data or an error
      if (result && result.data && result.data.length) {
        setData(result.data)
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred while fetching admin data:", error);

      setIsLoading(false);
      setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
    }
  };

  const getStatesData = async () => {
    setIsLoading(true);
    setStates([])
    try {
      const result = await dispatch(getStates());
      if (result && result.data && result.data.length) {
        setStates(result.data)
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred while fetching states data:", error);

      setIsLoading(false);
      setAlert({ show: true, type: 'error', message: error?.response?.data?.error || "An error occurred." });
    }
  }

  const addAdmin = async () => {
    setIsLoading(true);
    try {
      await adminInfoSchema.validate(adminData, {
        abortEarly: false,
      });

      const data = {
        ...adminData,
        states: JSON.stringify(adminData.states)
      }

      dispatch(addAdmins(data))
        .then((result) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'success', message: "New Admin added successfully" });
          handleClose()
          getAdminData(pageType)
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'error', message: err?.response.data?.error });
        });
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setErrors(formattedErrors);
      setIsLoading(false);
    }
  }

  const deleteUser = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        type: "Admin"
      }

      dispatch(deleteAccount(params, id))
        .then((result) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'success', message: "Account deleted successfully" });
          handleClose()
          getAdminData(pageType)
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'error', message: err?.response.data?.error });
        });
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setErrors(formattedErrors);
      setIsLoading(false);
    }
  }

  const suspendUser = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        type: "Admin"
      }

      dispatch(suspendAccount(params, id))
        .then((result) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'success', message: "Account suspended successfully" });
          handleClose()
          getAdminData(pageType)
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({ show: true, type: 'error', message: err?.response.data?.error });
        });
    } catch (validationError) {
      const formattedErrors = {};
      validationError.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });

      setErrors(formattedErrors);
      setIsLoading(false);
    }
  }


  return (
    <Template
      showHeader
      showSidebar
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu:true

      }}
      sidebarProps={{
        page: "Admin Management"
      }}
     
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <AlertBox open={alert.show} type={alert.type} message={alert.message} onChange={(value) => { setAlert({ show: value, type: '', message: "" }); }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "18.3em",
          marginTop: "4.5em",
        }}
      >
        {adminHeaders.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              background:
                pageType === item.label
                  ? "#FFFFFF 0% 0% no-repeat padding-box"
                  : "#F2F2F2 0% 0% no-repeat padding-box",
              borderRadius: "20px 20px 0px 0px",
              height: "59px",
              width: "189px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: pageType === item.label ? "#2D81FF" : "#000000",
              margin: "0px 4px",
              paddingTop: "2px",
              position: "relative",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px;"
            }}
          >
            <Box
              onClick={() => getAdminData(item.label)}
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  color:
                    pageType === item.label ? "#2593D6 !important" : "#000000",
                }}
              >
                <Icon
                  name={item.label === pageType ? item.selectedIcon : item.icon}
                  style={{
                    width: "17px",
                    height: "19px",
                    marginTop: "6px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  font: "normal normal normal 13px Poppins",
                  color: pageType === item.label ? "#2593D6" : "#000000",
                  marginLeft: "10px",
                }}
              >
                {item.label}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          border: "1px solid #FFFFFF",
          background:
            "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
          boxShadow: "15px 18px 50px #00000029",
          borderRadius: "24px",
          backgroundColor: "#F2F2F2",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          margin: "auto",
          width: isSmallScreen ? "100%" : "78%",
          marginLeft: isSmallScreen ? "0" : "17.5em",
          height: '100vh',
          overflow: 'scroll',
          paddingBottom: '2em'
        }}
      >
        {pageType === "Admin" && (
          <Button
            icon="AddUser"
            style={{
              width: "9em",
              marginTop: "auto",
              marginBottom: "14px",
              marginLeft: "auto",
            }}
            onClick={handleOpen}
          >
            Add Admin
          </Button>

        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddAdmin states={states} handleChange={handleAdminChange} addAdmin={addAdmin} errors={errors} data={adminData} onCancel={handleClose} isLoading={isLoading} />
        </Modal>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            mb: "1em",
          }}
        >
          <Grid isLoading={isLoading} data={data} columns={pageType === "Super Admin" ? superAdminColumns : adminColumns} type={pageType} deleteAccount={(userId) => deleteUser(userId)}
            suspendAccount={(userId) => suspendUser(userId)} />
        </Box>
      </Box>

    </Template>
  );
};

export default AdminManagement;
