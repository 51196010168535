import { Box, useMediaQuery } from "@mui/material";
import "./index.css";
import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { userHeaders } from "../../Helpers/DataHelper";
import { getUsers } from "../../Actions/users";
import Icon from "../../Helpers/Icon";
import AlertBox from "../../Components/Alert";
import Input from "../../Components/Core/Input";
import { useDispatch } from "react-redux";
import SelectInput from "../../Components/Core/Select";
import Button from "../../Components/Core/Button";
import AccountingCard from "../../Components/AccountingCard";
import { useTheme } from '@mui/material/styles';


const Accounting = () => {
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const [pageType, setPageType] = useState("Client");
  const [range, setRange] = useState("All");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    getUsersData(pageType, null);
  }, []);

  const getUsersData = async (type, text) => {
    setIsLoading(true);
    setPageType(type);
    setData([]);
    setSearchText(text);

    const params = {
      role: type,
      searchText: text && text.length ? text : null,
    };

    try {
      // const result = await dispatch(getUsers(params));
      setIsLoading(false);
      // if (result && result.data && result.data.length) {
      //     setData(result.data)
      // }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }
  };

  return (
    <Template
      showHeader
      showSidebar
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu: true

      }}
      sidebarProps={{
        page: "Accounting",
      }}

      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <AlertBox
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onChange={(value) => {
          setAlert({ show: value, type: "", message: "" });
        }}
      />
      <Box sx={{
        width: isSmallScreen ? "100%" : "85%",
        marginLeft: isSmallScreen ? "0" : "18em",
      }}>

        <Box
          sx={{
            position: "fixed",
            top: '3em',
            width: "83%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "33px",
              marginBottom: "12px",
              marginRight: "60px",
              justifyContent: "space-between",
            }}
          >
            <div className="dateDiv">
              <span className="toady">Today,</span>
              <span className="date">{formattedDate}</span>
            </div>

            <div style={{ display: "flex" }}>
              <SelectInput
                style={{ width: "174px", marginLeft: "1em", padding: "1.2px 0" }}
                isTransparent
                options={[
                  { value: "last week", label: "Last Week" },
                  { value: "last 30 days", label: "Last Month" },
                  { value: "last year", label: "Last Year" },
                  { value: "this year to date", label: "Year to Date" },
                  { value: "All", label: "All" },
                ]}
                value={range}
              />

              <Button
                style={{
                  width: "14em",
                  marginBottom: "14px",
                  marginLeft: "10px",
                  height: "41px",
                }}
              >
                Select Date Range
              </Button>
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "6px",
              marginLeft: '10px'
            }}
          >
            {userHeaders.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  background:
                    pageType === item.value
                      ? "#FFFFFF 0% 0% no-repeat padding-box"
                      : "#F2F2F2 0% 0% no-repeat padding-box",
                  borderRadius: "20px 20px 0px 0px",
                  height: "59px",
                  width: "189px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: pageType === item.value ? "#2D81FF" : "#000000",
                  margin: "0px 4px",
                  paddingTop: "2px",
                  position: "relative",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px;",
                }}
              >
                <Box
                  onClick={() => getUsersData(item.value, null)}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color:
                        pageType === item.value
                          ? "#2593D6 !important"
                          : "#000000",
                    }}
                  >
                    <Icon
                      name={
                        item.value === pageType ? item.selectedIcon : item.icon
                      }
                      style={{
                        width: "17px",
                        height: "19px",
                        marginTop: "6px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      font: "normal normal normal 13px Poppins",
                      color: pageType === item.value ? "#2593D6" : "#000000",
                      marginLeft: "10px",
                    }}
                  >
                    {item.label}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #FFFFFF",
            background: "#FFFFFFD9 0% 0% no-repeat padding-box",
            boxShadow: "4px 15px 50px #00000029",
            borderRadius: "24px",
            backgroundColor: "#F2F2F2",
            opacity: "1",
            padding: "1.5em",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            width: "90%",
            marginTop: "13.5em",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "-4px",
              marginBottom: "40px",
              justifyContent: "space-between",
            }}
          >
            <span
              className="allTransaction"
              style={{ font: "normal normal 600 19px/31px Poppins" }}
            >
              All {pageType} Transactions
            </span>

            <Input
              placeholder="Search"
              value={searchText}
              isTransparent={true}
              onChange={(e) => getUsersData(pageType, e.target.value)}
              style={{
                width: "28%",
                marginBottom: "15px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
              }}
            />
          </Box>

          <Box
            sx={{
              border: "1px solid #FFFFFF",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "15px",
              opacity: "1",
              padding: "1.5em",
              backdropFilter: "blur(50px)",
              WebkitBackdropFilter: "blur(50px)",
              height: '42vh',
              overflow: 'scroll'
            }}
          >
            <AccountingCard />
            <AccountingCard />
          </Box>
        </Box>
      </Box>
    </Template>
  );
};

export default Accounting;
