import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import {
  userHeaders,
  clientsColumns,
  usersColumns,
  analysisCard,
  unApprovedUsersList,
} from "../../Helpers/DataHelper";
import {
  getUnApprovedReviewer,
  getUnApprovedInspector,
  getUnApprovedAppraiser,
} from "../../Actions/users";
import Icon from "../../Helpers/Icon";
import {
  deleteAccount,
  suspendAccount,
  activateAccount,
} from "../../Actions/account";
import AlertBox from "../../Components/Alert";
import Input from "../../Components/Core/Input";
import Grid from "../../Components/DataGrid";
import AnalyticsCard from "../../Components/AnalyticsCard";
import { useDispatch } from "react-redux";
import Button from "../../Components/Core/Button";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  getAllClient,
} from "../../Actions/dashboard";
import {
 getUsers
} from "../../Actions/users";
import { apiUrls } from "../../Helpers/Constants";
import axios from "../../Utils/axios";

const UserManagement = () => {
  const [pageType, setPageType] = useState("Client");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [unApprovedUsers, setUnApprovedUsers] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [activeUsers, setActiveUser] = useState(0);
  const [monthlyUser, setMonthlyUser] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState("");
  const [analysisData, setAnalysisData] = useState([]);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  const [open, setOpen] = useState(false);

  const filterCurrentMonthData = (data) => {
    const currentMonth = dayjs().month(); // Get current month (0-11)
    const currentYear = dayjs().year(); // Get current year

    return data.filter((item) => {
      const itemDate = dayjs(item.createdAt); // Adjust based on your data structure
      return (
        itemDate.month() === currentMonth && itemDate.year() === currentYear
      );
    });
  };

  useEffect(() => {
    getUsersData(pageType, null);
  }, []);

  useEffect(() => {
    getUsersAnalyticsData();
  }, [analysisData]);

  const handleOpen = () => {
    setOpen(true);
    getUnApprovedUsersList(pageType);
  };
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getUsersData = async (type, text) => {
    setOpen(false);

    if (!text) {
      setIsLoading(true);
    }

    setPageType(type);
    setData([]);
    setSearchText(text);

    if (text === null) {
      setAnalysisData([]);
    }

    const params = {
      role: type,
    };

    if (type === "Client") {
      try {
        const result = await dispatch(getAllClient(params));
        if (text === null) {
          let data = result.data;
          setTotalUser(result.data.length);
          let active = data.filter((e) => {
            return e.status == 4 || 2;
          });
          setActiveUser(active.length);

          const filteredData = filterCurrentMonthData(data);
          setMonthlyUser(filteredData.length);
        } else {
          setIsLoading(false);
        }
        if (result && result.data && result.data.length) {
          getUsersAnalyticsData(params);
          setData(result.data);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);

        setIsLoading(false);
        setAlert({
          show: true,
          type: "error",
          message: error?.response?.data?.error || "An error occurred.",
        });
      }
    } else {
      try {
        const result = await dispatch(getUsers(params));
        if (text === null) {
          let data = result.data;
          setTotalUser(result.data.length);
          let active = data.filter((e) => {
            return e.status == 4;
          });
          setActiveUser(active.length);

          const filteredData = filterCurrentMonthData(data);
          setMonthlyUser(filteredData.length);
        } else {
          setIsLoading(false);
        }
        if (result && result.data && result.data.length) {
          getUsersAnalyticsData(params);
          setData(result.data);
        }
      } catch (error) {
        console.error("Error occurred while fetching data:", error);

        setIsLoading(false);
        setAlert({
          show: true,
          type: "error",
          message: error?.response?.data?.error || "An error occurred.",
        });
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getSearch();
  }, [searchText]);

  const getSearch = () => {
    let all_data = [...data];
    setSearchData(
      all_data.filter((user) => {
        const name =  user?.firstName?.toLowerCase();
        const email = user?.email?.toLowerCase() ;
        return (
          name.includes(searchText?.toLowerCase()) ||
          email.includes(searchText?.toLowerCase())
        );
      })
    );
  };

  const getUsersAnalyticsData = async () => {
    try {
      setIsLoading(true);
      const updatedAnalysisCardData = [
        {
          ...analysisCard[pageType].totalUsers,
          value: totalUser,
        },
        {
          ...analysisCard[pageType].totalThisMonth,
          value: monthlyUser,
          subText: true,
        },
        {
          ...analysisCard[pageType].activeUsers,
          value: activeUsers,
        },
      ];
      setAnalysisData(updatedAnalysisCardData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }
  };

  const getUnApprovedUsersList = async (type) => {
    setIsLoading(true);
    setUnApprovedUsers([]);

    const params = {
      role: type,
    };

    try {
      const result = await dispatch(getUnApprovedReviewer(params));
      setIsLoading(false);
      if (result && result.data && result.data.length) {
        setUnApprovedUsers(result.data);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);

      setIsLoading(false);
      setAlert({
        show: true,
        type: "error",
        message: error?.response?.data?.error || "An error occurred.",
      });
    }
  };

  const deleteUser = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        type: "User",
      };

      dispatch(deleteAccount(params, id))
        .then((result) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "success",
            message: "Account deleted successfully",
          });
          getUsersData(pageType, "");
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: err?.response.data?.error,
          });
        });
    } catch (validationError) {
      setIsLoading(false);
    }
  };

  const suspendUser = async (data) => {
    setIsLoading(true);
    try {
      const params = {
        type: "User",
      };

      dispatch(
        data.status === 3
          ? activateAccount(data._id)
          : suspendAccount(params, data._id)
      )
        .then((result) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "success",
            message: "Account updated successfully",
          });
          getUsersData(pageType, "");
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: err?.response.data?.error,
          });
        });
    } catch (validationError) {
      setIsLoading(false);
    }
  };

  const activateUser = async (id) => {
    setIsLoading(true);
    try {
      dispatch(activateAccount(id))
        .then((result) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "success",
            message: "Account activated successfully",
          });
          getUsersData(pageType, "");
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: err?.response.data?.error,
          });
        });
    } catch (validationError) {
      setIsLoading(false);
    }
  };

  const rejectUser = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        type: "User",
      };

      dispatch(suspendAccount(params, id))
        .then((result) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "success",
            message: "Account suspended successfully",
          });
          getUsersData(pageType, "");
        })
        .catch((err) => {
          setIsLoading(false);
          setAlert({
            show: true,
            type: "error",
            message: err?.response.data?.error,
          });
        });
    } catch (validationError) {
      setIsLoading(false);
    }
  };

  const onRowClick = async (row) => {
    navigate("/detail", { state: { type: pageType, id: row.id } });
  };

  return (
    <Template
      showHeader
      showSidebar
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu: true,
      }}
      sidebarProps={{
        page: "User Management",
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <AlertBox
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onChange={(value) => {
          setAlert({ show: value, type: "", message: "" });
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "18.3em",
          marginTop: "4.5em",
        }}
      >
        {userHeaders.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              background:
                pageType === item.value
                  ? "#FFFFFF 0% 0% no-repeat padding-box"
                  : "#F2F2F2 0% 0% no-repeat padding-box",
              borderRadius: "20px 20px 0px 0px",
              height: "59px",
              width: "189px",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: pageType === item.value ? "#2D81FF" : "#000000",
              margin: "0px 4px",
              paddingTop: "2px",
              position: "relative",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px;",
            }}
          >
            <Box
              onClick={() => {
                getUsersData(item.value, null);
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  color:
                    pageType === item.value ? "#2593D6 !important" : "#000000",
                }}
              >
                <Icon
                  name={item.value === pageType ? item.selectedIcon : item.icon}
                  style={{
                    width: "17px",
                    height: "19px",
                    marginTop: "6px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  font: "normal normal normal 13px Poppins",
                  color: pageType === item.value ? "#2593D6" : "#000000",
                  marginLeft: "10px",
                }}
              >
                {item.label}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {isLoading ? (
        <CircularProgress
          size={40}
          sx={{
            color: "#2C80FF",
            marginRight: "6px",
            display: "flex",
            margin: "Auto",
            marginTop: "15px",
          }}
        />
      ) : (
        <Box
          sx={{
            border: "1px solid #FFFFFF",
            background:
              "transparent linear-gradient(119deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box",
            boxShadow: "15px 18px 50px #00000029",
            borderRadius: "24px",
            backgroundColor: "#F2F2F2",
            opacity: "1",
            padding: "1.5em",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            margin: "auto",
            width: isSmallScreen ? "100%" : "78%",
            marginLeft: isSmallScreen ? "0" : "17.5em",
            height: '100vh',
            overflow: 'scroll',
            paddingBottom: '2em'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "initial",
              alignItems: "center",
              cursor: "pointer",
              margin: "0px 4px",
              paddingTop: "2px",
              position: "relative",
              marginBottom: "22px",
            }}
          >
            {analysisData.map((item, index) => (
              <AnalyticsCard
                key={index}
                number={item.value}
                icon={item.icon}
                text={item.label}
                month={item.month}
              />
            ))}

            <Box
              sx={{
                opacity: "1",
                padding: "1.5em",
                position: "absolute",
                top: "-20px",
                right: "0px",
              }}
            >
              {pageType !== "Client" && (
                <Button
                  icon="AddUser"
                  style={{
                    width: "12em",
                    marginTop: "auto",
                    marginBottom: "14px",
                    marginLeft: "auto",
                    height: "40px",
                  }}
                  onClick={handleOpen}
                >
                  Approve {pageType}
                </Button>
              )}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    mb: "1em",
                    width: "88%",
                    margin: "auto",
                    marginTop: "5em",
                  }}
                >
                  <Box
                    sx={{
                      mb: "1em",
                      width: "100%",
                      paddingTop: "12px",
                      textAlign: "center",
                      position: "relative",
                      paddingBottom: "2em",
                      marginBottom: "-24px",
                      backgroundColor: "white",
                      borderRadius: "14px",
                    }}
                  >
                    <span
                      style={{
                        color: "#000000",
                        fontSize: "20px",
                        font: "normal normal 600 20px/33px Poppins",
                      }}
                    >
                      {pageType} Request
                    </span>
                    <Icon
                      onClick={() => handleClose()}
                      name="Cancel"
                      style={{
                        width: "42px",
                        height: "42px",
                        position: "absolute",
                        right: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                  <Grid
                      isLoading={isLoading}
                      data={unApprovedUsers}
                      columns={unApprovedUsersList}
                      type={pageType}
                      showButtons="true"
                      activateUser={(userId) => activateUser(userId)}
                      rejectUser={(userId) => rejectUser(userId)}
                    />
                </Box>
              </Modal>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              mt: "2em",
              justifyContent: "end",
            }}
          >
            <Input
              placeholder="Search"
              value={searchText}
              isTransparent={true}
              onChange={(e) => setSearchText(e.target.value)}
              style={{
                width: "28%",
                marginBottom: "15px",
                backgroundColor: "#FFFFFF",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: "1em",
            }}
          >
            {pageType === "Client" && (
              <Grid
                isLoading={isLoading}
                data={
                  searchText
                    ? searchData.map((data) => ({
                        ...data,
                        totalProperties: data?.PropertyId?.length
                          ? data?.PropertyId?.length
                          : 0,
                      }))
                    : data.map((data) => ({
                        ...data,
                        totalProperties: data?.PropertyId?.length
                          ? data?.PropertyId?.length
                          : 0,
                      }))
                }
                columns={clientsColumns}
                type={pageType}
                deleteAccount={(userId) => deleteUser(userId)}
                suspendAccount={(userId) => suspendUser(userId)}
                onRowClick={(row) => onRowClick(row)}
              />
            )}
            {pageType === "Inspector" && (
              <Grid
                isLoading={isLoading}
                data={
                  searchText
                    ? searchData.map((user) => ({
                        ...user,
                        licenseNumber: user.licenseId[0].licenseNumber,
                      }))
                    : data.map((user) => ({
                        ...user,
                        licenseNumber: user.licenseId[0].licenseNumber,
                      }))
                }
                columns={usersColumns}
                type={pageType}
                deleteAccount={(userId) => deleteUser(userId)}
                suspendAccount={(userId) => suspendUser(userId)}
                onRowClick={(row) => onRowClick(row)}
              />
            )}

            {pageType === "Appraiser" && (
              <Grid
                isLoading={isLoading}
                data={searchText ? searchData : data}
                columns={usersColumns}
                type={pageType}
                deleteAccount={(userId) => deleteUser(userId)}
                suspendAccount={(userId) => suspendUser(userId)}
                onRowClick={(row) => onRowClick(row)}
              />
            )}
            {pageType === "Reviewer" && (
              <Grid
                isLoading={isLoading}
                data={searchText ? searchData : data}
                columns={usersColumns}
                type={pageType}
                deleteAccount={(userId) => deleteUser(userId)}
                suspendAccount={(userId) => suspendUser(userId)}
                onRowClick={(row) => onRowClick(row)}
              />
            )}
          </Box>
        </Box>
      )}
    </Template>
  );
};

export default UserManagement;
