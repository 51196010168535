import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Template from "../../Template";
import Ellipse8 from "../../Assets/Images/Ellipse8.svg";
import { Box, CircularProgress } from "@mui/material";
import Text from "../../Components/Core/Text";
import Input from "../../Components/Core/Input";
import {
  getSupportTokenList,
  getSupportTokenMessageByTokenId,
} from "../../Actions/message";
import { chatHeaders } from "../../Helpers/DataHelper";
import Icon from "../../Helpers/Icon";
import { io } from "socket.io-client";
import {
  ChatPlus,
  MenuCircleVertical,
  PlusThin,
  Search,
  Send,
} from "react-huge-icons/outline";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "../../Components/Alert";
import TicketInfo from "../../Components/TicketInfo";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import axios from "../../Utils/axios";
import { apiUrls } from "../../Helpers/Constants";

function Support() {
  const dispatch = useDispatch();
  const [pageType, setPageType] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });
  const auth = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);
  const [ticketDetail, setTicketDetail] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [ticketid, setticketid] = useState("");

  const [leftchat, setleftchat] = useState([]);
  const [rightchat, setrightchat] = useState([]);
  const [message, setmessage] = useState("");
  const [ticketsarray, setticketsarray] = useState([]);
  const [byiddata, setbyiddata] = useState({});

  const [open, setOpen] = useState(false);
  const handleClose = () =>{
    setShowMenu(false);
     setOpen(false);}

  dayjs.extend(relativeTime);

  const boxRef = useRef(null);


  useEffect(() => {
      const scrollToBottom = () => {
          if (boxRef.current) {
              boxRef.current.scrollTop = boxRef.current.scrollHeight;
          }
      };
      scrollToBottom();
    
  }, [leftchat?.length]);


  useEffect(()=>{
    fetchtickets();
  },[pageType]);



  const sendMessage = async () => {
    if (ticketid) {
      socketIo.emit("send_message", {
        ticketId: ticketid,
        sender: "Admin",
        timestamp: new Date(),
        message: message,
      });
      setrightchat([
        ...rightchat,
        {
          ticketId: ticketid,
          sender: "Admin",
          timestamp: new Date(),
          message: message,
        },
      ]);
      setmessage("");
    }
  };


  // // Event handler for input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    if (!event.target.value) {
      // setFilteredList(supportTokenLst);
    }
  };

  // Event handler for Enter key press
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // filterList();
    }
  };

  const fetchtickets = async () => {
    try {
      const res = await dispatch(getSupportTokenList());
      if(res){
        if (pageType === 'all') {
          setticketsarray(res.data.map((itm,idx) => ({ ...itm, checked: false })));
          setbyiddata("");
           setticketid("");
        }
        else if(pageType === "open"){
          setticketsarray(res.data.filter((data)=>{return data.status === 'Open'}).map((itm,idx) => ({ ...itm, checked: false })));
          setbyiddata("");
          setticketid("");
        }else if(pageType === "closed"){
          setticketsarray(res.data.filter((data)=>{return data.status === 'Close'}).map((itm,idx) => ({ ...itm, checked: false })));
          setbyiddata("");
          setticketid("");
        }

      }
    } catch (err) {
      console.log(err);
    }
   
  };



  const socketIo = useMemo(() => io('https://dev-ndd-newapi.flynautstaging.com/', {
    transports: ['websocket'], // Try forcing WebSocket
    reconnectionAttempts: 5, // Retry connecting up to 5 times
}), []);

useEffect(() => {
    const handleConnect = () => {
        fetchtickets();
        console.log('Connected to backend:', socketIo.id);
    };

    const handleDisconnect = () => {
        console.log('Disconnected from backend');
    };

    const handleConnectError = (err) => {
        console.log('Connection Error:', err);
    };

    socketIo.on('connect', handleConnect);
    socketIo.on('disconnect', handleDisconnect);
    socketIo.on('connect_error', handleConnectError);

    return () => {
        socketIo.off('connect', handleConnect);
        socketIo.off('disconnect', handleDisconnect);
        socketIo.off('connect_error', handleConnectError);
    };
}, []);

useEffect(() => {
    if (socketIo && ticketid) {
        socketIo.emit("join_room", ticketid);

        const handleReceiveMessage = (message) => {
            console.log(message, "Received message");
            if (message.sender !== "Admin") {
                setleftchat((prevChat) => [...prevChat, message]);
            }
            console.log(message, "message");
        };

        socketIo.on("receive_message", handleReceiveMessage);

        if (rightchat.length === 0) {
          const res = axios.get(`/admin/tickets/details?ticketId=${ticketid}`).then((res) => {
                if (res) {
                    // Filter messages by sender "Inspector"
                    setleftchat(res.data.data[0].messages.filter((itm) => itm.sender !== "Admin"))
                    setrightchat(res.data.data[0].messages.filter((itm) => itm.sender === "Admin"));
                }

            });

            // services.getticketById(ticketid).then((res) => {
            //     if (res) {
            //         // Filter messages by sender "Inspector"
            //         setleftchat(res.data.data[0].messages.filter((itm) => itm.sender === "Admin"))
            //         setrightchat(res.data.data[0].messages.filter((itm) => itm.sender === "Inspector"));
            //     }

            // });
        }

        return () => {
            socketIo.off("receive_message", handleReceiveMessage);
        };
    }
}, [socketIo, ticketid, rightchat.length]);

  const fetchData = async () => {
    try {
      const res = await dispatch(
        getSupportTokenMessageByTokenId(ticketid)
      );
      if (res) {
        setleftchat(
          res.data[0].messages.filter((itm) => itm.sender !== "Admin")
        );
        setrightchat(
          res.data[0].messages.filter((itm) => itm.sender === "Admin")
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Template
      showHeader
      showSidebar
      headerProps={{
        showSearch: true,
        showNotification: true,
        showProfile: true,
        showsideMenu: true,
      }}
      sidebarProps={{
        page: "support-token",
      }}
      containerStyle={{
        backgroundImage: `url(${Ellipse8})`,
      }}
    >
      <AlertBox
        open={alert.show}
        type={alert.type}
        message={alert.message}
        onChange={(value) => {
          setAlert({ show: value, type: "", message: "" });
        }}
      />

      <Box
        sx={{
          border: "1px solid #FFFFFF",
          boxShadow: "4px 15px 50px #00000029;",
          borderRadius: "24px",
          backgroundColor: "#FFFFFF",
          opacity: "1",
          padding: "1.5em",
          backdropFilter: "blur(50px)",
          WebkitBackdropFilter: "blur(50px)",
          width: "78%",
          margin: "auto",
          marginLeft: "17.5em",
          marginTop: "4.5em",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ mr: "auto" }}>
            <Text
              variant="h2"
              style={{ font: "normal normal 600 19px/31px Poppins" }}
            >
              All Support Ticket
            </Text>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "auto",
            marginTop: "6px",
            marginBottom: "-20px",
          }}
        >
          {chatHeaders.map((item, index) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                background:
                  pageType === item.value
                    ? "#FFFFFF 0% 0% no-repeat padding-box"
                    : "#F2F2F2 0% 0% no-repeat padding-box",
                borderRadius: "20px 20px 0px 0px",
                height: "59px",
                width: "189px",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: pageType === item.value ? "#2D81FF" : "#000000",
                margin: "0px 4px",
                paddingTop: "2px",
                position: "relative",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px;",
              }}
            >
              <Box
                onClick={() =>{
                  setPageType(item.value)}}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    color:
                      pageType === item.value
                        ? "#2593D6 !important"
                        : "#000000",
                  }}
                >
                  <Icon
                    name={
                      item.value === pageType ? item.selectedIcon : item.icon
                    }
                    style={{
                      width: "17px",
                      height: "19px",
                      marginTop: "6px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    font: "normal normal normal 13px Poppins",
                    color: pageType === item.value ? "#2593D6" : "#000000",
                    marginLeft: "10px",
                  }}
                >
                  {item.label}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            boxShadow: "15px 15px 50px #00000029",
            borderRadius: "24px",
            background: "#F8F8F8 0% 0% no-repeat padding-box",
            display: "flex",
            flexDirection: "row",
            opacity: 1,
            padding: "1.5em",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            margin: "auto",
            height: "59vh",
            overflow: "hidden",
            mt: "1em",
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={40}
              sx={{
                color: "#2C80FF",
                display: "flex",
                margin: "Auto",
                marginTop: "15px",
              }}
            />
          ) : (
            <>
              <Box
                sx={{
                  mr: "auto",
                  width: "30%",
                  borderRight: "1.6px solid #ccc",
                  paddingRight: "1.6em",
                  mr: "1em",
                }}
              >
                <Input
                  placeholder="Search"
                  isTransparent
                  style={{
                    width: "100%",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "17px",
                    height: 56,
                  }}
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  icon={<Search style={{ color: "#A8A8A8", marginTop: -12 }} />}
                />
                <Box
                  sx={{
                    height: "80vh",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "0px",
                    },
                  }}
                >
                  {ticketsarray.map((itm, index) => (
                    <Box
                      style={{
                        background: itm.checked ? "#fff" : "transparent",
                        borderRadius: itm.checked ? 10 : 0,
                        padding: "0.5em",
                        width: "90%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "1em",
                        cursor: "pointer",
                        borderBottom: "1px solid #ccc",
                      }}
                      onClick={() => {
                        const copydata = [...ticketsarray];
                        setticketid(itm._id);
                        setbyiddata({ ...itm });
                        setticketsarray(
                          copydata.map((itms, ind) => ({
                            ...itms,
                            checked: index == ind ? true : false,
                          }))
                        );
                        setleftchat([]);
                        setrightchat([]);
                      }}
                    >
                          <Text
                          style={{
                            color: "#2D81FF",
                            font: "normal normal 600 13px/25px Poppins",
                            fontSize: "10px",
                            fontWeight: "600",
                          }}
                        >
                          {itm?.messages[0]?.sender}
                        </Text>
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                  
                        <Text
                          style={{
                            color: "#011502",
                            font: "normal normal 600 13px/25px Poppins",
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          {itm.ticketTitle}
                        </Text>
                        <Text
                          style={{
                            color: itm.status == "Open" ? "#35C736" : "#EF2B2C",
                            marginLeft: "auto",
                            font: "normal normal normal 11px/16px Poppins",
                            fontSize: "11px",
                          }}
                        >
                          {itm.status}
                        </Text>
                      </Box>
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        <Text
                          style={{
                            color: "#818181",
                            font: "normal normal normal 12px Poppins",
                            fontSize: "11px",
                            marginTop: "-.2em",
                          }}
                        >
                          {itm?.messages[itm?.messages.length - 1]?.message}
                        </Text>
                        <Text
                          style={{
                            color: "#8D8D8D",
                            marginLeft: "auto",
                            font: "normal normal normal 10px Poppins",
                            fontSize: "10px",
                          }}
                        >
                          {moment(itm.updatedAt).fromNow()}
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box sx={{ width: "66%", paddingX: "1em" }}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      position: "relative",
                    }}
                  >
                

                    <Text
                      style={{
                        color: "#011502",
                        font: "normal normal 600 13px/25px Poppins",
                        fontSize: "17px",
                        fontWeight: "600",
                      }}
                    >
                      {byiddata ? byiddata.ticketTitle : ""}

                      <span
                        style={{
                          color:
                            byiddata?.status == "Open"
                              ? "#35C736"
                              : "#EF2B2C",
                          font: "normal normal normal 11px/16px Poppins;",
                          fontSize: "11px",
                          fontWeight: "600",
                          marginLeft: "0.5em",
                        }}
                      >
                        {byiddata ? byiddata.status : ""}
                      </span>
                    </Text>
                    <Text
                      style={{
                        color: "#011502",
                        font: "normal normal normal 11px/16px Poppins;",
                        fontSize: "11px",
                        fontWeight: "600",
                      }}
                    >
                      {byiddata ? `Ticket No: # ${byiddata._id}` : ""}
                    </Text>
                  </div>
                  {byiddata?
                  <MenuCircleVertical
                    fontSize={32}
                    onClick={() => setShowMenu(!showMenu)}
                    color="#d6d848"
                    style={{ marginLeft: "auto", cursor: "pointer" }}
                  />:
                  <></>
                  
                }
                  {showMenu && (
                    <Box
                      onClick={()=>setOpen(!open)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        boxShadow: "0px 10px 20px #0000000D",
                        borderRadius: "12px !important",
                        padding: "1em",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        mt: "2em",
                      }}
                    >
                      {/* {["Ticket Title"].map((item, index) => (
                        <Box
                          sx={{
                            cursor: "pointer",
                            // mb: index === 2 ? 0 : "1em",
                          }}
                        > */}
                          <Text
                            style={{
                              // color: item === "Report" ? "#FF0000" : "#4C5056",
                              font: "normal normal normal 14px/21px Poppins",
                              fontSize: "13.5px",
                            }}
                          >
                            {byiddata.ticketTitle}
                          </Text>
                        {/* </Box>
                      ))} */}
                    </Box>
                  )}

                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                    tabIndex={0}
                    sx={{ 
                      border: '1px solid black',
                      '&:focus': {
                        border: 'none',
                        outline: 'none', 
                      },
                      width:"100vw"
                    }}
                    >
                      {byiddata && (
                        <TicketInfo
                          data={byiddata}
                          handleClose={handleClose}
                        />
                      )}
                    </Box>
                  </Modal>
                </Box>

                <Box
                  sx={{
                    height: "80vh",
                    overflowY: "scroll",
                    pr: "1em",
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#F0F0F0",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#2C80FF",
                      borderRadius: "4px",
                    },
                    scrollbarColor: "#2C80FF #F0F0F0",
                    scrollbarWidth: "thin",
                  }}
                  ref={boxRef}
                >
                  {isLoading1 ? (
                    <CircularProgress
                      size={40}
                      sx={{
                        color: "#2C80FF",
                        display: "flex",
                        margin: "Auto",
                        marginTop: "15px",
                      }}
                    />
                  ) : (
                    <>
                      {(ticketid ? [...rightchat, ...leftchat] : [])
                        .sort((a, b) => {
                          const timeA = new Date(a?.timestamp).getTime();
                          const timeB = new Date(b?.timestamp).getTime();

                          if (timeA === timeB) {
                            return a.sender === "Admin" ? 1 : -1;
                          }

                          return timeA - timeB;
                        })
                        .reduce((acc, itm, index, array) => {
                          const currentDate = dayjs(itm.timestamp).format(
                            "YYYY-MM-DD"
                          );
                          const prevDate =
                            index > 0
                              ? dayjs(array[index - 1].timestamp).format(
                                  "YYYY-MM-DD"
                                )
                              : null;

                          const isToday = dayjs(itm.timestamp).isSame(
                            new Date(),
                            "day"
                          );
                          const isYesterday = dayjs(itm.timestamp).isSame(
                            dayjs().subtract(1, "day"),
                            "day"
                          );

                          if (currentDate !== prevDate) {
                            acc.push(
                              <Box
                                sx={{
                                  width: "100%",
                                  textAlign: "center",
                                  color: "gray.400",
                                  fontSize: "12px",
                                  my: 4,
                                }}
                                key={`date-${currentDate}`}
                              >
                                {isToday
                                  ? "Today"
                                  : isYesterday
                                  ? "Yesterday"
                                  : dayjs(itm.timestamp).format("DD MMMM YYYY")}
                              </Box>
                            );
                          }

                          acc.push(
                            <Box
                              key={index}
                              sx={{
                                width: "95%",
                                display: "flex",
                                px: 2,
                                justifyContent: "space-between",
                                mb: 2,
                              }}
                            >
                              {itm.sender !== "Admin" ? (
                                <>
                                  <Box
                                    sx={{
                                      backgroundColor: "#FFFFFF",
                                      width: "auto",
                                      maxWidth: "40%",
                                      color: "black",
                                      borderRadius: "8px 8px 8px 0",
                                      p: 2,
                                      px: 4,
                                    }}
                                  >
                                    {itm?.message}
                                  </Box>
                                  <Text
                                    style={{
                                      color: "#4F4F4F",
                                      opacity: 0.4,
                                      ml: 2,
                                      alignSelf: "flex-end",
                                    }}
                                  >
                                    {dayjs(itm.timestamp).format("HH:mm")}
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <Text
                                    style={{
                                      color: "#4F4F4F",
                                      opacity: 0.4,
                                      ml: 2,
                                      alignSelf: "flex-end",
                                    }}
                                  >
                                    {dayjs(itm.timestamp).format("HH:mm")}
                                  </Text>
                                  <Box
                                    sx={{
                                      backgroundColor: "#2593D6",
                                      width: "auto",
                                      maxWidth: "70%",
                                      color: "#FFFFFF",
                                      borderRadius: "8px 8px 0 8px",
                                      p: 2,
                                      px: 4,
                                    }}
                                  >
                                    {itm?.message}
                                  </Box>
                                </>
                              )}
                            </Box>
                          );

                          return acc;
                        }, [])}
                    </>
                  )}
                </Box>


                { ticketid !="" &&

                <Box
                  sx={{
                    background: "#2593D6",
                    display: "flex",
                    flexDirection: "row",
                    padding: ".7em",
                    mt: "1em",
                    height: 44,
                    borderRadius: 24,
                  }}
                >
                  <Box
                    sx={{
                      background: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      width: 46,
                      height: 42,
                      cursor: "pointer",
                      mt: "auto",
                      mb: "auto",
                    }}
                  >
                    <PlusThin fontSize={32} />
                  </Box>
                  <Box
                    sx={{
                      ml: ".7em",
                      width: "100%",
                      background: "#fff",
                      borderRadius: "24px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Input
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sendMessage(); // Call sendMessage function when Enter key is pressed
                        }
                      }}
                      placeholder="Type a message"
                      style={{
                        width: "95%",
                        background: "#fff",
                        borderRadius: 18,
                        paddingLeft: ".5em",
                      }}
                    />
                    <Box
                      sx={{
                        background: "#00476a",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        width: 32,
                        height: 32,
                        cursor: "pointer",
                        mt: ".4em",
                        mr: ".5em",
                      }}
                    >
                      <Send
                        onClick={sendMessage}
                        fontSize={18}
                        color="#fff"
                        style={{
                          transform: "rotate(375deg)",
                          marginTop: ".1em",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                }
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Template>
  );
}

export default Support;
