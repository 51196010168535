import React, { useEffect, useState } from "react";
import logo from "../Assets/Images/logo.png";
import { Box, useMediaQuery } from "@mui/material";
import "./index.css";
import Icon from "../Helpers/Icon";
import { AiOutlineMore } from "react-icons/ai";
import PopMenu from "../Components/PopMenu";
import { useSelector } from "react-redux";
import { getInitials } from "../Helpers/Constants";
import PopNotificaton from "../Components/PopNotification";
import { useTheme } from "@mui/material/styles";
import { RxHamburgerMenu } from "react-icons/rx";
import PopSideMenu from "../Components/PopSideMenu";

const Header = ({
  transparent = false,
  backgroundImage = "",
  showSearch = false,
  showNotification = false,
  showProfile = false,
  showsideMenu = false
}) => {
  const auth = useSelector((state) => state.auth);
  const [showMenu, setshowMenu] = useState(false);
  const [showNotify, setshowNotify] = useState(false);
  const [showSideMenu, setshowSideMenu] = useState(false);
  const [storedData, setStoredData] = useState(() => JSON.parse(localStorage.getItem("user")) || {});



  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        position: backgroundImage ? 'relative' : 'fixed',
        zIndex: backgroundImage ? 0:  1,
        width: '96%',
        height: backgroundImage ? "260px" : "auto",
        background: transparent
          ? `transparent url(${backgroundImage}) 0% 0% no-repeat padding-box`
          : "none",
        display: "flex",
        flexDirection: "row",
        padding: backgroundImage ? "5.3em 1em 0 2em" : "1.5em 1em 1.5em 2em",
        justifyContent: "right",
        backgroundSize: "cover",
      }}
    >

      
      <div >
        {isSmallScreen && showsideMenu && (
          <RxHamburgerMenu
            style={{
              width: "20px",
              height: "20px",
              marginRight: "1em",
              cursor: "pointer",
              position: "fixed",
              left: "20px",
            }}
            onClick={() => setshowSideMenu(!showSideMenu)}
          />
        )}

        {showSideMenu && (
          <PopSideMenu handleClose={() => setshowSideMenu(!showSideMenu)} page={"Dashboard"}/>
        )}
      </div>
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "140px",
          height: "33px",
          marginRight: "auto",
          cursor: "pointer",
          position: "absolute",
          left: "71px",
        }}
      />


      <div style={{ display: "flex" }}>
        {showNotification && (
          <Icon
            name="Bell_Icon"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "1em",
              cursor: "pointer",
            }}
            onClick={() => setshowNotify(!showNotify)}
          />
        )}
        {showNotify && (
          <PopNotificaton handleClose={() => setshowNotify(!showNotify)} />
        )}

        {showProfile && (
          <Box
            sx={{
              boxShadow: "0px 3px 6px #00000029",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              borderRadius: "50%",
              padding: ".5em",
              height: "20px",
              mt: "-7px",
              width: "20px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => setshowMenu(!showMenu)}
          >
            <span
              style={{
                font: " normal normal 600 15px/23px Poppins",
                color: "#2C80FF",
              }}
            >
              {getInitials(storedData?.firstName, storedData?.lastName)}
            </span>
          </Box>
        )}
        {showProfile && (
          <AiOutlineMore
            size={20}
            style={{
              cursor: "pointer",
              marginTop: "1px",
              color: "#2C80FF",
            }}
            onClick={() => setshowMenu(!showMenu)}
          />
        )}
        {showMenu && <PopMenu handleClose={() => setshowMenu(!showMenu)} />}
      </div>
    </Box>
  );
};

export default Header;
