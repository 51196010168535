import axios from "../Utils/axios";
import { authConstants, usersConstants } from "./constants";
import { apiUrls } from "../Helpers/Constants";

export const getUsers = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_REQUEST });

        try {
            const res = await axios.get(apiUrls.getUsers, { params });

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.USERS_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.USERS_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};


export const getInspectors = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.CLIENT_REQUEST });

        try {
            const res = await axios.get(apiUrls.getClients, { params: params });

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.CLIENT_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.CLIENT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getClients = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.CLIENT_REQUEST });

        try {
            const res = await axios.get(apiUrls.getClients, { params: params });

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.CLIENT_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.CLIENT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch admins'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching admins:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getUsersCount = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_COUNT_REQUEST });

         if(params.role === 'Client'){
        try {
            const res = await axios.get(apiUrls.getAllClient, { params });

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.USERS_COUNT_SUCCESS,
                    payload: {
                        total: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.USERS_COUNT_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users count'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users count:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    }
    };
};

// export const getUnApprovedUsers = (params) => {
//     return async (dispatch) => {
//         dispatch({ type: usersConstants.UNAPPROVED_USERS_REQUEST });

//         try {
//             const res = await axios.get(apiUrls.getUnapprovedUsers, { params });

//             if (res.status === 200) {
//                 dispatch({
//                     type: usersConstants.UNAPPROVED_USERS_SUCCESS,
//                     payload: {
//                         users: res.data.data,
//                         message: res.data.message,
//                     },
//                 });

//                 return res.data; // Return the data for the component to use
//             } else {
//                 dispatch({
//                     type: usersConstants.UNAPPROVED_USERS_FAILURE,
//                     payload: { error: res.data },
//                 });
//                 throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
//             }
//         } catch (error) {
//             console.error("Error fetching users:", error);
//             throw error; // Rethrow the error to be caught by the component
//         }
//     };
// };

export const getUnApprovedReviewer = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.UNAPPROVED_USERS_REQUEST });

        try {
            const res = await axios.get(apiUrls.getOnHoldUsers, { params });

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.UNAPPROVED_USERS_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.UNAPPROVED_USERS_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};



export const getUnApprovedInspector = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.UNAPPROVED_USERS_REQUEST });

        try {
            const res = await axios.get(apiUrls.getallholdinspector);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.UNAPPROVED_USERS_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.UNAPPROVED_USERS_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};


export const getUnApprovedAppraiser = (params) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.UNAPPROVED_USERS_REQUEST });

        try {
            const res = await axios.get(apiUrls.getallholdappraisal);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.UNAPPROVED_USERS_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.UNAPPROVED_USERS_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getUserDetail = (id) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_DETAIL_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getUserDetail}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.USERS_DETAIL_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.USERS_DETAIL_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const updateUserDetail = (id, data) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_DETAIL_REQUEST });

        try {
            const res = await axios.post(`${apiUrls.updateUserDetail}${id}`, data);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.USERS_DETAIL_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.USERS_DETAIL_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getClientDetail = (id) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_DETAIL_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getClientDetail}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.USERS_DETAIL_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.USERS_DETAIL_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getAllOrdersByClientId = (id) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.CLIENT_ORDER_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getAllOrders}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.CLIENT_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.CLIENT_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getAllPropertyByClientId = (id) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.CLIENT_PROPERTY_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getAllProperty}${id}`);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.CLIENT_PROPERTY_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.CLIENT_PROPERTY_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};

export const getAssignedOrderOfAppraiser = (id) => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_DETAIL_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getAppraiserOrder}?appraiserid=${id}`);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.CLIENT_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.CLIENT_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};



export const getRemainingOrder = () => {
    return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_DETAIL_REQUEST });

        try {
            const res = await axios.get(`${apiUrls.getRemainingOrders}`);

            if (res.status === 200) {
                dispatch({
                    type: usersConstants.CLIENT_ORDER_SUCCESS,
                    payload: {
                        users: res.data.data,
                        message: res.data.message,
                    },
                });

                return res.data; // Return the data for the component to use
            } else {
                dispatch({
                    type: usersConstants.CLIENT_ORDER_FAILURE,
                    payload: { error: res.data },
                });
                throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
            }
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error; // Rethrow the error to be caught by the component
        }
    };
};


export const appraiserOrderAssign = (id,data) => {
      return async (dispatch) => {
        dispatch({ type: usersConstants.USERS_DETAIL_SUCCESS});
        const res = await axios.post(`${apiUrls.assignAppraiserOrder}?appraiserid=${id}`, data);
        if (res.status === 200) {
          dispatch({
            type: usersConstants.CLIENT_ORDER_SUCCESS,
            payload: {
              user: res.data.data,
              message: "Orders Assigned Successfully",
            },
          });
        } else {
          if (res.status === 400) {
            dispatch({
              type: authConstants.CLIENT_ORDER_FAILURE,
              payload: { error: res.data },
            });
          }
        }
      };
    };



    export const getClientOrders = (id) => {
        return async (dispatch) => {
            dispatch({ type: usersConstants.USERS_DETAIL_REQUEST });
    
            try {
                const res = await axios.get(`${apiUrls.getClientOrders}?clientid=${id}`);
    
                if (res.status === 200) {
                    dispatch({
                        type: usersConstants.USERS_DETAIL_SUCCESS,
                        payload: {
                            users: res.data.data,
                            message: res.data.message,
                        },
                    });
    
                    return res.data; // Return the data for the component to use
                } else {
                    dispatch({
                        type: usersConstants.USERS_DETAIL_FAILURE,
                        payload: { error: res.data },
                    });
                    throw new Error(res.data.error || 'Failed to fetch users'); // Throw an error to be caught by the component
                }
            } catch (error) {
                console.error("Error fetching users:", error);
                throw error; // Rethrow the error to be caught by the component
            }
        };
    };
    